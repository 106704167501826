import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {RxStomp, RxStompState} from '@stomp/rx-stomp';
import {BehaviorSubject, combineLatest, concatMap, defer, EMPTY, firstValueFrom, from, Observable, share} from 'rxjs';
import {distinctUntilChanged, filter, finalize, map, skip} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {selectOnline, selectUser} from '../../auth/auth-store/auth.selectors';
import {UserHelper} from '../../model/user-helper';
import {webSocketConnectionFailed, webSocketConnectionReset} from '../../root-store/notification/notification.actions';
import {MonitoringHelper} from '../../util/monitoring/monitoring-helper';
import {Nullable} from '../../util/type-helper';
import {AuthService} from '../auth/auth.service';
import {User} from '../auth/userEntity';
import {SimulatedDateService} from '../simulated-date/simulated-date.service';

const WS_ENDPOINT_DEV = 'wss://unused/websocket';

/**
 * Service handling Websocket Connection
 *
 * Automatically reconnects on errors and after a logout (when the ws was started before) and on network reconnects.
 */
@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  /** this counts re-connects, and is reset on a successful connection or re-established internet connection */
  retries = 0;
  readonly MAX_RETRIES = 6;
  readonly watcherCount$ = new BehaviorSubject(0);

  private destinationToChannel = new Map<string, Observable<string>>();

  constructor(
    private rxStompService: RxStomp,
    private store: Store,
    private authService: AuthService,
    private window: Window,
    private simulatedDateService: SimulatedDateService
  ) {
    this.initStompServiceConfiguration();
    if (environment.endpoints.backendWebsocketUrl === WS_ENDPOINT_DEV) {
      this.store.dispatch(webSocketConnectionFailed()); // disable websockets on local dev/mock setup
    }

    // reset connect retries on successful connect:
    this.rxStompService.connectionState$.pipe(filter(s => s === RxStompState.OPEN)).subscribe(() => {
      this.store.dispatch(webSocketConnectionReset());
      this.retries = 0;
    });

    this.rxStompService.webSocketErrors$
      .pipe(
        filter(() => this.retries >= 2) // skip first retries (we don't want to monitor recoverable errors like session timeout)
      )
      .subscribe(errEvent => {
        // In Instana many errors will onyl show {trusted: true} as error message,
        // because errors from differnt domains get "cleaned" by the browser.
        // See https://stackoverflow.com/a/44862693
        MonitoringHelper.noticeError(new Error(`WEBSOCKET ERROR: ${JSON.stringify(errEvent)}`), {
          connectionRetries: `${this.retries}`,
          terminallyFailed: `${this.retries >= this.MAX_RETRIES}`,
          webSocketUrl: environment?.endpoints?.backendWebsocketUrl
        });
      });

    this.rxStompService.stompErrors$
      .pipe(
        filter(() => this.retries >= 2) // skip first retries (we don't want to monitor recoverable errors like session timeout)
      )
      .subscribe(frame => {
        MonitoringHelper.noticeError(new Error(`STOMP ERROR: ${JSON.stringify(frame)}`), {
          connectionRetries: `${this.retries}`,
          terminallyFailed: `${this.retries >= this.MAX_RETRIES}`,
          webSocketUrl: environment?.endpoints?.backendWebsocketUrl
        });
      });

    const userChanged$ = this.store.pipe(
      select(selectUser),
      distinctUntilChanged((u1, u2) => UserHelper.getZkk(u1) === UserHelper.getZkk(u2))
    );
    const networkChanged$ = this.store.pipe(select(selectOnline), distinctUntilChanged());
    combineLatest([userChanged$, networkChanged$, this.watcherCount$, this.authService.getAccessToken()])
      .pipe(
        map(
          ([user, online, watcherCount, token]) => AuthService.isValidToken(token, this.simulatedDateService.getSimulatedDate()) && WebsocketService.isZkkPresent(user) && online && watcherCount > 0
        ),
        distinctUntilChanged(),
        skip(1), // skip first which is always `false` (watcherCount==0)
        concatMap(connect => {
          // assure re-activation waits for deactivate to complete!
          if (connect) {
            this.retries = 0;
            if (environment.endpoints.backendWebsocketUrl !== WS_ENDPOINT_DEV) {
              this.rxStompService.activate();
            }
            return EMPTY;
          } else {
            return from(this.disconnectWebsocket());
          }
        })
      )
      .subscribe();
  }

  private initStompServiceConfiguration(): void {
    const configUrl = environment.endpoints.backendWebsocketUrl;
    const fullUrl = configUrl.startsWith('wss://') ? configUrl : `wss://${this.window.location.host}${configUrl}`;
    this.rxStompService.configure({
      brokerURL: fullUrl,
      beforeConnect: (client: RxStomp): Promise<void> => this.setStompAuthorizationHeader(client),
      heartbeatIncoming: 30000,
      heartbeatOutgoing: 30000,
      splitLargeFrames: true,
      reconnectDelay: 500
    });
  }

  public async disconnectWebsocket(): Promise<void> {
    await this.rxStompService.deactivate();
  }

  public getConnectionSate(): BehaviorSubject<RxStompState> {
    return this.rxStompService.connectionState$;
  }

  public watch(destination: string): Observable<string> {
    if (!this.destinationToChannel.has(destination)) {
      const sharedChannel = defer(() => {
        this.watcherCount$.next(this.watcherCount$.value + 1);
        return this.rxStompService.watch(destination);
      }).pipe(
        map(message => message.body),
        finalize(() => this.watcherCount$.next(this.watcherCount$.value - 1)),
        share()
      );
      this.destinationToChannel.set(destination, sharedChannel);
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.destinationToChannel.get(destination)!;
  }

  async setStompAuthorizationHeader(client: RxStomp): Promise<void> {
    if (this.retries > this.MAX_RETRIES) {
      this.disconnectWebsocket();
      this.store.dispatch(webSocketConnectionFailed());
    }
    this.retries++;
    const user = await firstValueFrom(
      this.store.pipe(
        select(selectUser),
        filter(u => !!UserHelper.getZkk(u))
      )
    );
    const accessToken = await firstValueFrom(this.authService.getAccessToken().pipe(filter(token => AuthService.isValidToken(token, this.simulatedDateService.getSimulatedDate()))));
    const zkk = UserHelper.getZkk(user);

    const connectHeaders = {Authorization: `Bearer ${accessToken}`};
    if (UserHelper.isSbbUser(user)) {
      connectHeaders[environment.disableXZkkHeader === 'active' ? 'X-Capri-PartnerId' : 'X-Zkk'] = zkk;
    }
    client.configure({connectHeaders: connectHeaders});
  }

  private static isZkkPresent(u: Nullable<User>): boolean {
    return !!u && (!UserHelper.isSbbUser(u) || !!u.activePartner);
  }
}
